import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import TurtleMascot from './TurtleMascot';

const AddTermForm = ({ onTermAdded }) => {
    const [productName, setProductName] = useState('');
    const [translations, setTranslations] = useState({
        en: '',
        da: '',
        fi: '',
        is: '',
        no: '',
        sv: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (language, value) => {
        setTranslations((prevTranslations) => ({
            ...prevTranslations,
            [language]: value,
        }));
    };

    const handleSubmit = async () => {
        const newTerm = {
            productName: productName.trim(),
            translations: { ...translations },
        };

        try {
            await onTermAdded(newTerm); // Delegate API call to Home.js
            setSuccessMessage('Term added successfully!');
            setTimeout(() => setSuccessMessage(''), 3000);

            setProductName('');
            setTranslations({
                en: '',
                da: '',
                fi: '',
                is: '',
                no: '',
                sv: '',
            });
        } catch (error) {
            setErrorMessage('Error adding term. Please try again.');
            console.error('Error adding term:', error);
            setTimeout(() => setErrorMessage(''), 3000);
        }
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                Add Term with Product Name
            </Typography>

            {successMessage && <TurtleMascot message={successMessage} type="success" />}
            {errorMessage && <TurtleMascot message={errorMessage} type="error" />}

            <TextField
                fullWidth
                label="Product Name"
                variant="outlined"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                placeholder="Enter the product name"
                style={{ marginBottom: '20px' }}
            />

            <Box display="flex" flexDirection="column" gap="10px">
                {['en', 'da', 'fi', 'is', 'no', 'sv'].map((language) => (
                    <TextField
                        key={language}
                        fullWidth
                        label={`Translation (${language.toUpperCase()})`}
                        variant="outlined"
                        value={translations[language]}
                        onChange={(e) => handleInputChange(language, e.target.value)}
                        placeholder={`Enter translation in ${language.toUpperCase()}`}
                    />
                ))}
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: '20px' }}
            >
                Add Term
            </Button>
        </Box>
    );
};

export default AddTermForm;
