import React, { useState } from 'react'; // Import React and useState
import { Box, TextField, Button, Typography, Select, MenuItem } from '@mui/material'; // Import Material-UI components

function BatchTranslationForm({ languages, onBatchTranslate }) {
    const [inputText, setInputText] = useState('');
    const [sourceLanguage, setSourceLanguage] = useState(languages[0]);
    const [targetLanguage, setTargetLanguage] = useState(languages[1]);

    const handleTranslate = async () => {
        if (!inputText.trim()) {
            alert('Please enter some text to translate.');
            return;
        }

        try {
            const data = { inputText, sourceLanguage, targetLanguage };
            await onBatchTranslate(data); // Pass data to parent for handling
        } catch (error) {
            console.error('Error during translation:', error);
            alert('Failed to process the translation. Please try again.');
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Batch Translation
            </Typography>
            <Box display="flex" gap={2} marginBottom={2}>
                <TextField
                    label="Enter Ingredients List (comma-separated)"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                />
                <Box>
                    <Typography variant="body1">Source Language</Typography>
                    <Select
                        value={sourceLanguage}
                        onChange={(e) => setSourceLanguage(e.target.value)}
                        fullWidth
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                                {lang.toUpperCase()}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant="body1">Target Language</Typography>
                    <Select
                        value={targetLanguage}
                        onChange={(e) => setTargetLanguage(e.target.value)}
                        fullWidth
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                                {lang.toUpperCase()}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>
            <Button variant="contained" color="primary" onClick={handleTranslate}>
                Translate
            </Button>
        </Box>
    );
}

export default BatchTranslationForm;
