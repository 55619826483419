import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    TablePagination,
    MenuItem,
    Select,
    Collapse,
    IconButton,
    Badge,
} from '@mui/material';
import CommentList from './CommentList';
import { ExpandMore, ExpandLess, Comment } from '@mui/icons-material';

function TermList({
    terms = [],
    setTerms = () => {},
    onTermSelect = () => {},
    selectedTermId,
    onStatusChange = () => {},
}) {
    const [expandedTerm, setExpandedTerm] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // 0-based index for TablePagination
    const rowsPerPage = 10; // Number of rows per page

    const languages = ['en', 'da', 'fi', 'is', 'no', 'sv'];

    const handleExpandClick = (termId) => {
        setExpandedTerm((prev) => (prev === termId ? null : termId));
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Confirmed':
                return '#cdf7e0';
            case 'Outdated':
                return '#f2cfce';
            case 'Pending':
                return '#c6ebf7';
            default:
                return 'transparent';
        }
    };

    const handleTermClick = (termId, language) => {
        if (termId) onTermSelect(termId, language);
    };

    return (
        <Paper style={{ padding: '1rem' }}>
            <Typography variant="h6" gutterBottom>
                Term List
            </Typography>
            {terms.length === 0 ? (
                <Typography>No terms available.</Typography>
            ) : (
                <>
                    <TableContainer component={Paper} style={{ maxHeight: 400 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product Name</TableCell>
                                    {languages.map((lang) => (
                                        <TableCell key={lang}>{lang.toUpperCase()}</TableCell>
                                    ))}
                                    <TableCell>Status</TableCell>
                                    <TableCell>Comments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {terms
                                    .slice(
                                        currentPage * rowsPerPage,
                                        currentPage * rowsPerPage + rowsPerPage
                                    )
                                    .map((term) => (
                                        <React.Fragment key={term?._id || Math.random()}>
                                            <TableRow
                                                style={{
                                                    backgroundColor:
                                                        selectedTermId === term._id
                                                            ? '#f0f8ff'
                                                            : getStatusColor(term.status),
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <TableCell>{term?.productName || 'N/A'}</TableCell>
                                                {languages.map((lang) => {
                                                    const commentCount =
                                                        term.translationComments?.[lang] || 0;
                                                    return (
                                                        <TableCell
                                                            key={`${term._id}-${lang}`}
                                                            onClick={() =>
                                                                handleTermClick(term._id, lang)
                                                            }
                                                        >
                                                            <Box>
                                                                <Typography>
                                                                    {term.translations?.[lang] ||
                                                                        'N/A'}
                                                                </Typography>
                                                                {commentCount > 0 && (
                                                                    <Badge
                                                                        badgeContent={commentCount}
                                                                        color="primary"
                                                                    >
                                                                        <Comment
                                                                            style={{
                                                                                fontSize: '1rem',
                                                                                color: '#3f51b5',
                                                                                marginLeft: '5px',
                                                                            }}
                                                                        />
                                                                    </Badge>
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell>
                                                    <Select
                                                        value={term.status || ''}
                                                        onChange={(e) =>
                                                            onStatusChange(term._id, e.target.value)
                                                        }
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="">No Status</MenuItem>
                                                        <MenuItem value="Confirmed">
                                                            Confirmed ✅
                                                        </MenuItem>
                                                        <MenuItem value="Outdated">
                                                            Outdated 🚫
                                                        </MenuItem>
                                                        <MenuItem value="Pending">Pending ⏳</MenuItem>
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label={`View comments for ${
                                                            term.productName || 'Term'
                                                        }`}
                                                        aria-expanded={
                                                            expandedTerm === term._id
                                                        }
                                                        onClick={() =>
                                                            handleExpandClick(term._id)
                                                        }
                                                    >
                                                        {expandedTerm === term._id ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}
                                                    </IconButton>
                                                    {languages.some(
                                                        (lang) =>
                                                            term.translationComments?.[lang] > 0
                                                    ) && (
                                                        <Comment
                                                            style={{
                                                                fontSize: '1rem',
                                                                color: '#3f51b5',
                                                                marginLeft: '8px',
                                                            }}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    colSpan={languages.length + 3}
                                                    style={{ padding: 0 }}
                                                >
                                                    <Collapse
                                                        in={expandedTerm === term._id}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <Box margin={2}>
                                                            <CommentList
                                                                termId={term._id}
                                                                apiKey={
                                                                    process.env.REACT_APP_API_KEY
                                                                }
                                                            />
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={terms.length}
                        page={currentPage}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                    />
                </>
            )}
        </Paper>
    );
}

export default TermList;
