import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import TranslationPage from './pages/TranslationPage'; // Import the Translation Page
import UserGuide from './pages/UserGuide'; // Import the User Guide
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme'; // Adjust the path based on your structure
import IdleLogout from './components/IdleLogout'; // Import the IdleLogout component

Amplify.configure(awsExports);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <IdleLogout timeout={15 * 60 * 1000} /> {/* Add the IdleLogout component */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/translation" element={<TranslationPage />} />
                    <Route path="/userguide" element={<UserGuide />} /> {/* User Guide Route */}
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
