import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#007bff', // Blue
        },
        secondary: {
            main: '#ff5722', // Orange
        },
        error: {
            main: '#ff0000', // Red for error states
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '8px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.notFoundTerm': {
                        color: '#ff0000', // Explicit red for not-found terms
                    },
                },
            },
        },
    },
});

export default theme;
