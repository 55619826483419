import React, { useState } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Select,
    MenuItem,
} from '@mui/material';

function TranslationComparisonForm({ languages, onCompare, terms }) {
    const [sourceText, setSourceText] = useState('');
    const [targetText, setTargetText] = useState('');
    const [sourceLanguage, setSourceLanguage] = useState(languages[0]);
    const [targetLanguage, setTargetLanguage] = useState(languages[1]);
    const [errorMessage, setErrorMessage] = useState('');

    // Helper to parse input text, respecting quotation marks
    const parseInput = (text) => {
        const regex = /"([^"]+)"|([^,]+)/g;
        const matches = [];
        let match;
        while ((match = regex.exec(text)) !== null) {
            matches.push((match[1] || match[2]).trim().toLowerCase());
        }
        return matches;
    };

    const handleCompare = () => {
        setErrorMessage(''); // Clear any previous errors

        if (!sourceText.trim() || !targetText.trim()) {
            setErrorMessage('Both source and translated texts are required.');
            return;
        }

        try {
            // Parse source and target texts with the helper
            const sourceTerms = parseInput(sourceText);
            const targetTerms = parseInput(targetText);

            const results = sourceTerms.map((sourceTerm, index) => {
                const matchingTerm = terms.find((t) => {
                    const translations = t.translations || {}; // Safeguard for undefined translations
                    return (
                        translations[sourceLanguage]?.toLowerCase() === sourceTerm &&
                        translations[targetLanguage]?.toLowerCase() === targetTerms[index]
                    );
                });

                if (!matchingTerm) {
                    return {
                        sourceTerm,
                        targetTerm: targetTerms[index] || '<missing>',
                        matchStatus: 'No match in database',
                        alternatives: [],
                    };
                }

                const alternatives = terms
                    .filter((t) => {
                        const translations = t.translations || {}; // Safeguard for undefined translations
                        return translations[sourceLanguage]?.toLowerCase() === sourceTerm;
                    })
                    .map((t) => ({
                        translation: t.translations?.[targetLanguage] || '<No translation>',
                        productName: t.productName || 'N/A',
                    }));

                const isExactMatch =
                    matchingTerm.translations?.[targetLanguage]?.toLowerCase() === targetTerms[index];

                return {
                    sourceTerm,
                    targetTerm: targetTerms[index] || '<missing>',
                    matchStatus: isExactMatch ? 'Match found' : 'Mismatch or alternative found',
                    alternatives,
                };
            });

            onCompare(results);
        } catch (error) {
            console.error('Error during comparison:', error);
            setErrorMessage('Failed to compare translations. Please try again later.');
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Translation Comparison
            </Typography>
            <Box display="flex" flexDirection="column" gap={2} marginBottom={2}>
                <TextField
                    label='Source Text (e.g., "Phrase 1", "Phrase 2")'
                    variant="outlined"
                    multiline
                    rows={3}
                    value={sourceText}
                    onChange={(e) => setSourceText(e.target.value)}
                />
                <TextField
                    label='Translated Text (e.g., "Translation 1", "Translation 2")'
                    variant="outlined"
                    multiline
                    rows={3}
                    value={targetText}
                    onChange={(e) => setTargetText(e.target.value)}
                />
                <Box display="flex" gap={2}>
                    <Box>
                        <Typography>Source Language</Typography>
                        <Select
                            value={sourceLanguage}
                            onChange={(e) => setSourceLanguage(e.target.value)}
                            fullWidth
                        >
                            {languages.map((lang) => (
                                <MenuItem key={lang} value={lang}>
                                    {lang.toUpperCase()}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box>
                        <Typography>Target Language</Typography>
                        <Select
                            value={targetLanguage}
                            onChange={(e) => setTargetLanguage(e.target.value)}
                            fullWidth
                        >
                            {languages.map((lang) => (
                                <MenuItem key={lang} value={lang}>
                                    {lang.toUpperCase()}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
                <Button variant="contained" color="primary" onClick={handleCompare}>
                    Compare
                </Button>
            </Box>
            {errorMessage && (
                <Typography variant="body2" color="error" style={{ marginTop: '1rem' }}>
                    {errorMessage}
                </Typography>
            )}
        </Box>
    );
}

export default TranslationComparisonForm;