import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, CircularProgress } from '@mui/material';

const BASE_URL = (process.env.REACT_APP_API_BASE_URL || 'https://api.herbieterms.com').replace(/\/$/, '');

const CommentList = ({ termId, apiKey }) => {
    const [comments, setComments] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchComments = async () => {
            setLoading(true);
            setErrorMessage('');

            try {
                const response = await fetch(`${BASE_URL}/terms/${termId}/comments`, {
                    headers: { 'x-api-key': apiKey },
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch comments: ${response.statusText}`);
                }

                const data = await response.json();
                setComments(data);
            } catch (error) {
                console.error('Error fetching comments:', error.message);
                setErrorMessage('Failed to load comments. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (termId && apiKey) {
            fetchComments();
        }
    }, [termId, apiKey]);

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Comments
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : errorMessage ? (
                <Typography color="error">{errorMessage}</Typography>
            ) : comments.length > 0 ? (
                <List>
                    {comments.map((comment) => (
                        <ListItem key={comment._id}>
                            <Typography variant="body1">
                                {comment.language?.toUpperCase()}: {comment.text} -{' '}
                                <strong>{comment.createdBy}</strong> -{' '}
                                {comment.createdAt
                                    ? new Date(comment.createdAt).toLocaleString()
                                    : 'Unknown date'}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography>No comments have been added for this term.</Typography>
            )}
        </Box>
    );
};

export default CommentList;
