import React, { useState } from 'react';
import { Button, Typography, Paper } from '@mui/material';
import TurtleMascot from './TurtleMascot';
import axios from 'axios';

function FileUpload({ onTermsAdded }) {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // State to manage message type

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file before uploading.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            // Retrieve the API key from environment variables
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await axios.post('https://api.herbieterms.com/upload', formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-api-key': apiKey, // Include the API key in headers
                },
            });

            onTermsAdded(response.data);
            setMessage('File uploaded successfully!');
            setMessageType('success');
        } catch (error) {
            console.error('Error uploading file:', error.response?.data || error.message);
            setMessage('Oops! Upload failed. Please check the file format and the column names and try again.');
            setMessageType('error');
        }

        // Clear the message after 5 seconds
        setTimeout(() => {
            setMessage('');
            setMessageType('');
        }, 5000);
    };

    return (
        <>
            <Paper style={{ padding: '1rem', marginTop: '2rem' }}>
                <Typography variant="h6" gutterBottom>
                    Upload Excel File
                </Typography>
                <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
                <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginTop: '1rem' }}>
                    Upload and Add Terms
                </Button>
            </Paper>
            {message && <TurtleMascot message={message} type={messageType} />}
        </>
    );
}

export default FileUpload;
