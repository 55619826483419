import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const IdleLogout = ({ timeout = 15 * 60 * 1000 }) => {
    const navigate = useNavigate();
    const timerRef = useRef(null);

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            handleLogout();
        }, timeout);
    };

    const handleLogout = () => {
        try {
            // Clear session storage or cookies
            sessionStorage.clear();
            localStorage.clear();

            // Redirect to the sign-in page
            navigate('/');
        } catch (error) {
            console.error('Error during sign out:', error);
        }
    };

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'scroll', 'click'];

        // Reset the timer on user activity
        events.forEach((event) => window.addEventListener(event, resetTimer));

        // Start the timer initially
        resetTimer();

        // Cleanup the event listeners and timer on component unmount
        return () => {
            events.forEach((event) => window.removeEventListener(event, resetTimer));
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [timeout]);

    return null; // This component doesn't render anything
};

export default IdleLogout;
