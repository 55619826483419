import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';

const BASE_URL = (process.env.REACT_APP_API_BASE_URL || 'https://api.herbieterms.com').replace(/\/$/, '');

function CommentForm({ termId, language, onCommentAdded }) {
    const [commentText, setCommentText] = useState('');
    const [createdBy, setCreatedBy] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!commentText.trim() || !createdBy.trim()) {
            alert('Please fill in all fields.');
            return;
        }

        const newComment = {
            text: commentText,
            createdBy,
            language,
        };

        try {
            await onCommentAdded(newComment);
            setCommentText('');
            setCreatedBy('');
        } catch (error) {
            console.error('Error adding comment:', error);
            alert('Failed to add comment. Please try again.');
        }
    };

    return (
        <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
            <Typography variant="h6" gutterBottom>
            Please fill in all fields
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Your Name"
                    value={createdBy}
                    onChange={(e) => setCreatedBy(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label={`Add Comment for ${language.toUpperCase()}`}
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit Comment
                </Button>
            </form>
        </Paper>
    );
}

export default CommentForm;
