import React, { useState, useEffect, useRef } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import TermList from '../components/TermList';
import CommentForm from '../components/CommentForm';
import AddTermForm from '../components/AddTermForm';
import BatchTranslationForm from '../components/BatchTranslationForm';
import FileUpload from '../components/FileUpload';
import FileUploadInstructions from '../components/FileUploadInstructions';
import MascotWithTooltip from '../components/MascotWithTooltip';
import TurtleMascot from '../components/TurtleMascot';
import { Typography, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Card } from '@mui/material';
import IdleLogout from '../components/IdleLogout'; // Adjust path as needed
import TranslationComparisonForm from '../components/TranslationComparisonForm';
import CommentList from '../components/CommentList';

import magnify from '../components/magnify.png';
import dict from '../components/dict.png';
import translates from '../components/translates.png';
import rocket from '../components/rocket.png';
import notes from '../components/notes.png';
import welcomes from '../components/welcomes.png';

// Centralize Axios Instance
const api = axios.create({
    baseURL: 'https://api.herbieterms.com',
    withCredentials: true,
});

// Interceptor to attach Authorization headers
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        const apiKey = process.env.REACT_APP_API_KEY;
        if (apiKey) {
            config.headers['x-api-key'] = apiKey;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

function Home() {
    const [terms, setTerms] = useState([]);
    const [filteredTerms, setFilteredTerms] = useState([]);
    const [products, setProducts] = useState([]);
    const [batchResults, setBatchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTermId, setSelectedTermId] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [commentSuccessMessage, setCommentSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [noResultsMessage, setNoResultsMessage] = useState('');
    const commentFormRef = useRef(null);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    const [comparisonResults, setComparisonResults] = useState([]);

    useEffect(() => {
        const fetchInitialTerms = async () => {
            try {
                await fetchTerms(currentPage);
            } catch (error) {
                console.error('Error during initial fetch:', error);
                setErrorMessage('Failed to load terms. Please try again later.');
            }
        };

        fetchInitialTerms();
    }, [currentPage]);

    const fetchTerms = async () => {
        setLoading(true);
        try {
            const response = await api.get('/terms');
            const termsData = Array.isArray(response.data) ? response.data : [];
            setTerms(termsData);
            setFilteredTerms(termsData);
            console.log('Fetched Terms:', termsData);
        } catch (error) {
            console.error('Error fetching terms:', error.message);
            setErrorMessage('Failed to fetch terms. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    // Pass terms to TermList
    <TermList
        terms={filteredTerms}
        setTerms={setTerms}
        onTermSelect={handleTermSelect}
        selectedTermId={selectedTermId}
        onStatusChange={handleStatusChange}
    />
    
    
   const fetchCommentsForTerms = async (termsData) => {
        return Promise.all(
            termsData.map(async (term) => {
                const translationComments = {};
                for (const lang of Object.keys(term.translations || {})) {
                    try {
                        const commentsResponse = await api.get(`/terms/${term._id}/comments?language=${lang}`);
                        translationComments[lang] = commentsResponse.data?.length || 0;
                    } catch (error) {
                        console.error(`Error fetching comments for term ${term._id}, language ${lang}:`, error);
                        translationComments[lang] = 0;
                    }
                }
                return { ...term, translationComments };
            })
        );
    };

    const handleSearchClick = () => {
        if (!searchTerm.trim()) {
            // Reset the filtered list if searchTerm is empty
            setFilteredTerms(terms);
            setNoResultsMessage('');
            return;
        }
    
        const filtered = terms.filter((term) => {
            if (!term || !term.translations) return false;
    
            const productNameMatch = term.productName?.toLowerCase().includes(searchTerm.toLowerCase());
            const translationsMatch = Object.values(term.translations).some((translation) =>
                translation?.toLowerCase().includes(searchTerm.toLowerCase())
            );
    
            return productNameMatch || translationsMatch;
        });
    
        setFilteredTerms(filtered);
    
        if (filtered.length === 0) {
            setNoResultsMessage(`No results found for "${searchTerm}"`);
            setTimeout(() => setNoResultsMessage(''), 3000); // Clear message after 3 seconds
        } else {
            setNoResultsMessage('');
        }
    };

    const handleTermSelect = (termId, language) => {
        console.log('Term selected:', termId, language); // Debug
        setSelectedTermId(termId);
        setSelectedLanguage(language);
    
        if (commentFormRef.current) {
            commentFormRef.current.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => window.scrollBy({ top: -100, behavior: 'smooth' }), 500);
        }
    };

    const handleTermAdded = async (newTerm) => {
        try {
            const response = await api.post('/terms', newTerm); // Use centralized Axios
            const addedTerm = response.data;

            setTerms((prevTerms) => [...prevTerms, addedTerm]);
            setFilteredTerms((prevTerms) => [...prevTerms, addedTerm]);
            setSuccessMessage('Term added successfully!');
            setTimeout(() => setSuccessMessage(''), 3000);

            if (!products.includes(addedTerm.productName)) {
                setProducts((prevProducts) => [...prevProducts, addedTerm.productName]);
            }
        } catch (error) {
            console.error('Error adding term:', error.response?.data || error.message);
            setErrorMessage('Error adding term. Please try again.');
            setTimeout(() => setErrorMessage(''), 3000);
        }
    };

    const handleCommentAdded = async (commentData) => {
        if (!selectedTermId) {
            alert('No term selected to add a comment.');
            return;
        }
        try {
            const response = await api.post(`/terms/${selectedTermId}/comments`, commentData);
            const updatedTerm = response.data;

            setTerms((prevTerms) =>
                prevTerms.map((term) => (term._id === updatedTerm._id ? updatedTerm : term))
            );
            setFilteredTerms((prevTerms) =>
                prevTerms.map((term) => (term._id === updatedTerm._id ? updatedTerm : term))
            );
            setCommentSuccessMessage('Comment added successfully!');
            setTimeout(() => setCommentSuccessMessage(''), 3000);
        } catch (error) {
            console.error('Error adding comment:', error);
            alert('Failed to add comment. Please try again.');
        }
    };

    const handleFetchComments = async (termId) => {
        try {
            const response = await api.get(`/terms/${termId}/comments`); // Ensure the endpoint is correct
            return response.data;
        } catch (error) {
            console.error('Error fetching comments:', error);
            throw error;
        }
    };

    const handleStatusChange = async (termId, newStatus) => {
        if (!termId || !newStatus) {
            console.error('Term ID or new status is missing.');
            return;
        }
        try {
            const response = await api.put(`/terms/${termId}`, { status: newStatus });
            const updatedTerm = response.data;

            setTerms((prevTerms) =>
                prevTerms.map((term) => (term._id === termId ? { ...term, status: updatedTerm.status } : term))
            );
            setFilteredTerms((prevTerms) =>
                prevTerms.map((term) => (term._id === termId ? { ...term, status: updatedTerm.status } : term))
            );
        } catch (error) {
            console.error('Error updating term status:', error);
            alert('Failed to update term status. Please try again.');
        }
    };

    const handleBatchTranslate = async ({ inputText, sourceLanguage, targetLanguage }) => {
        try {
            const response = await api.post('/batch-translate', { inputText, sourceLanguage, targetLanguage });

            if (!response.data || !Array.isArray(response.data)) {
                console.error('Invalid response from batch-translate API:', response.data);
                alert('Failed to fetch translation results.');
                return null;
            }

            const updatedResults = response.data.map((result) => ({
                ...result,
                translations: result.translations.map((t) => ({
                    ...t,
                    translation: t.translation || result.term,
                    isMissing: t.translation === `<span style="color:red">${result.term}</span>`,
                })),
            }));

            setBatchResults(updatedResults);
            return updatedResults;
        } catch (error) {
            console.error('Error during batch translation:', error);
            alert('Batch translation failed. Please try again.');
            return null;
        }
    };

    const handleTermsAdded = (newTerms) => {
        setTerms((prevTerms) => [...prevTerms, ...newTerms]);
        setFilteredTerms((prevTerms) => [...prevTerms, ...newTerms]);
        const newProducts = newTerms.map((term) => term.productName).filter((productName) => !products.includes(productName));
        if (newProducts.length > 0) {
            setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        }
    };

    return (
        <Authenticator
            components={{
                Header() {
                    return (
                        <Box
                            component="img"
                            src={welcomes}
                            alt="Welcome"
                            sx={{
                                width: 230,
                                marginBottom: 60,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto',
                            }}
                        />
                    );
                },
            }}
        >
            {({ signOut }) => (
                <div style={{ padding: '2rem', position: 'relative' }}>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
                            Search Terms
                        </Typography>
                        <MascotWithTooltip instruction="Use this section to search for terms in the database." mascotImage={magnify} />
                    </Box>
                    <SearchBar searchTerm={searchTerm} onSearchTermChange={setSearchTerm} onSearchClick={handleSearchClick} />
                    {noResultsMessage && <TurtleMascot message={noResultsMessage} type="error" />}
                    <Box style={{ display: 'flex', alignItems: 'center', marginTop: '2rem', marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
                            Term List
                        </Typography>
                        <MascotWithTooltip instruction="This list displays all the terms in the database." mascotImage={dict} />
                    </Box>
                    <TermList
                        terms={filteredTerms}
                        setTerms={setTerms}
                        onTermSelect={handleTermSelect}
                        onStatusChange={handleStatusChange}
                    />
                    {selectedTermId && (
    <>
        <div ref={commentFormRef}>
            <Box>
                <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontWeight: 'bold', marginBottom: '20px' }}
                >
                    Comments for Selected Term
                </Typography>
                <CommentList termId={selectedTermId} apiKey={process.env.REACT_APP_API_KEY} />
                <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                    Add a Comment
                </Typography>
                <CommentForm
                    termId={selectedTermId}
                    language={selectedLanguage}
                    onCommentAdded={handleCommentAdded}
                />
            </Box>
        </div>
        {commentSuccessMessage && (
            <TurtleMascot message={commentSuccessMessage} type="success" />
        )}
    </>
)}
                    <Box style={{ display: 'flex', alignItems: 'center', marginTop: '2rem', marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
                            Add Term
                        </Typography>
                        <MascotWithTooltip instruction="Add new terms to the database." mascotImage={notes} />
                    </Box>
                    <Card variant="outlined" sx={{ padding: 2 }}>
                        <AddTermForm onTermAdded={handleTermAdded} />
                    </Card>
                    {successMessage && <TurtleMascot message={successMessage} type="success" />}
                    <Box style={{ display: 'flex', alignItems: 'center', marginTop: '2rem', marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
                            Batch Translation
                        </Typography>
                        <MascotWithTooltip instruction="Translate a batch of ingredients in one go." mascotImage={translates} />
                    </Box>
                    <Card variant="outlined" sx={{ padding: 2 }}>
                        <BatchTranslationForm
                            languages={['en', 'da', 'fi', 'is', 'no', 'sv']}
                            onBatchTranslate={handleBatchTranslate}
                        />
                    </Card>
                    {batchResults.length > 0 && (
                        <Box marginTop={4}>
                            <Typography variant="h6" gutterBottom>
                                Batch Translation Results
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Term</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>Translation(s)</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>Product(s)</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {batchResults.map((result, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{result.term}</TableCell>
                                            <TableCell>
                                                {result.translations.map((t, idx) => (
                                                    <span
                                                        key={idx}
                                                        style={{
                                                            color: t.isMissing ? 'red' : 'inherit', // Apply red color for missing translations
                                                        }}
                                                    >
                                                        {t.isMissing ? result.term : t.translation}
                                                        {idx < result.translations.length - 1 && '/'}{' '}
                                                        {/* Add '/' between translations */}
                                                    </span>
                                                ))}
                                            </TableCell>
                                            <TableCell>
                                                {result.translations.map((t, idx) => (
                                                    <span key={idx}>
                                                        {t.products || 'N/A'}
                                                        {idx < result.translations.length - 1 && ', '}{' '}
                                                        {/* Add ',' between products */}
                                                    </span>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box marginTop={2}>
                                <Typography variant="body1">
                                    <strong>Source List:</strong>{' '}
                                    {batchResults.map((r, idx) => (
                                        <React.Fragment key={idx}>
                                            {r.term}
                                            {idx < batchResults.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Target List:</strong>{' '}
                                    {batchResults.map((r, idx) => (
                                        <span key={idx}>
                                            {idx > 0 && ', '}
                                            <span
                                                style={{
                                                    color: r.translations[0]?.isMissing ? 'red' : 'inherit',
                                                }}
                                            >
                                                {r.translations[0]?.isMissing ? r.term : r.translations[0]?.translation}
                                            </span>
                                        </span>
                                    ))}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box style={{ display: 'flex', alignItems: 'center', marginTop: '2rem', marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
                            Upload Excel File
                        </Typography>
                        <MascotWithTooltip instruction="Upload an Excel file to add multiple terms." mascotImage={rocket} />
                    </Box>
                    <FileUploadInstructions />
                    <FileUpload onTermsAdded={handleTermsAdded} />
                    <Box
                        style={{
                            position: 'absolute',
                            top: '20px',
                            right: '240px',
                            zIndex: 10,
                        }}
                    >
                        <Button variant="contained" color="secondary" onClick={() => navigate('/translation')}>
                            Request translation
                        </Button>
                    </Box>
                    <Box
                        style={{
                            position: 'absolute',
                            top: '20px',
                            right: '100px',
                            zIndex: 10,
                        }}
                    >
                        <Button variant="contained" color="primary" onClick={() => navigate('/userguide')}>
                            User Guide
                        </Button>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginRight: '10px' }}>
                            Translation Comparison
                        </Typography>
                        <MascotWithTooltip instruction="Compare translations against the term database." mascotImage={translates} />
                    </Box>
                    <Card variant="outlined" sx={{ padding: 2 }}>
                        <TranslationComparisonForm
                            languages={['en', 'da', 'fi', 'is', 'no', 'sv']}
                            onCompare={setComparisonResults}
                            terms={terms}
                        />
                    </Card>
                    {comparisonResults.length > 0 && (
                        <Box>
                            <Typography variant="h6">Comparison Results</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Source Term</TableCell>
                                        <TableCell>Translated Term</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Alternatives</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {comparisonResults.map((result, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{result.sourceTerm}</TableCell>
                                            <TableCell>{result.targetTerm}</TableCell>
                                            <TableCell>{result.matchStatus}</TableCell>
                                            <TableCell>
                                                {result.alternatives.map((alt, i) => (
                                                    <div key={i}>
                                                        {alt.translation} ({alt.productName})
                                                    </div>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                    <button onClick={signOut}>Sign out</button>
                </div>
            )}
        </Authenticator>
    );
}

export default Home;